// import {downFile} from "./http"
import CryptoJS from 'crypto-js'

/** 图片配置 */
const img_config = {
  /** 是否开启js图片解密功能 */
  web_encode_img :true,
  /** 开启懒加载功能 */
  use_lazy: true,
  /** 小程序图片解密worker */
  applet_work: "/wocker_div",
  /** 小程序图片非解密worker */
  applet_work_none: "/wocker_div_none",
  /** 正则 是否已经添加tupworker */
  applet_work_reg: "^/wocker_div",
  // 正则 加密域名判断
  // url_reg: "^http(s?)://([a-zA-Z0-9]+(\\\.\|-))?ssl((\\\.)?[a-zA-Z0-9\-]+)+/",
  // 正则 加密域名判断 ssl- ssl. xx-ssl- xx.ssl. xx-ssl. xx.ssl- 
  // url_reg: "^http(s?)://([a-zA-Z0-9\-]+(\\\.[a-zA-Z0-9\-]+)*(\\\.|-))?ssl(\\\.|-)",
  url_reg: "^http(s?)://([a-zA-Z0-9\-]+(\\\.[a-zA-Z0-9\-]+)*(\\\.|-))?ssl",
  // 开发模式直接将加密域名换成未加密域名
  url_reg_repalce_dev: "http$1://",
  // 加密域名特殊桶未加密
  url_reg_sepcial: ["^dev"],
  // 不能加密的后缀 正则
  no_encode_reg: ".svga?$",
}

/** 获取配置信息 */
export function getConfig(key) {
  return key?img_config[key]:img_config
}
/** 设置配置信息 */
export function setImgConfig(params={}) {
  Object.assign(img_config,params)
}

/** 判断是否是加密域名 */
export function isEncodeUrl(url) {
  if (/^http(s?):\/\//.test(url)) {
    const encrypt_domain = url.match(new RegExp(getConfig("url_reg")))
    if (encrypt_domain && !(new RegExp(getConfig("no_encode_reg")).test(url))) {
      // 注意加密域名中有特殊桶是未加密的
      // 取出域名后的路径
      const url_path = url.slice(encrypt_domain[0].length)
      const sepcial_reg = getConfig("url_reg_sepcial")
      for (let k in sepcial_reg) {
        if (new RegExp(sepcial_reg[k]).test(url_path)) {
          return false
        }
      }
      return true
    }
  }
  return false
}

/**
 * 下载加密图片 
 * resolve undefine 则是没有解密方法
 * catch 是加密图片下载失败 
 * @param {*} url 
 */
export function downloadEncodeImg(url) {
  if (window.fetchAndDec) {
    return window.fetchAndDec(url).then(content=>{
      return content?`data:image/${suffix(url)};base64,${content}`:Promise.reject("下载加密图片失败")
    })
  } else {
    return Promise.resolve(undefined)
  }
}

/** 取后缀 */
export function  suffix(url) {
  if (!url) return ""
  const arr = url.split(".")
  const _suffix=arr[arr.length-1]
  switch (_suffix) {
    case "jpeg":
      return "jpg"
    default:
      return _suffix
  }
}

// /**
//  * 下载图片
//  * @param {*} url 
//  */
// export function downloadImg(url) {
//   if (isEncodeUrl(url)) {
//     return downloadEncodeImg(url)
//   } else {
//     return downFile(url,{
//       responseType:'arraybuffer'
//     }).then(res=>{
//       return  `data:image/${suffix(url)};base64,` + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte),''));
//     })
//   }
// }

// base64解密(小说)
export function base64Decode(string_data) {
  try {
    let words = CryptoJS.enc.Base64.parse(string_data)
    let textString = CryptoJS.enc.Utf8.stringify(words);
    return textString
  }catch(error) {
    return ''
  }
}

/**
 * 图片影视等路径拼接
 * @param perfix  资源前缀
 * @param url     资源地址
 */
export function getImgUrl(perfix, url) {
  if (!url || /^http/.test(url)) {
    return url
  } else {
    return `${perfix}${/^\//.test(url) ? "" : "/"}${url}`
  }
}
