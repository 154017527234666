import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 检测设备信息 方法测试
import { checkDevice } from './utils/checkAgent'
console.log(checkDevice())
// // 全局图片组件
import cjImg from '@/components/cjImg'
Vue.component('cjImg', cjImg)
// // 获取图片路径
import { getImgUrl } from '@/utils/img'
Vue.prototype.$getImgUrl = getImgUrl

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
