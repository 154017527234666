<template>
  <!--
    使用iconfont时 src必须是 [icon_开头]
  -->
  <!-- <div class="cjimg" ref="cjimgRef" snipasteId="cjimg-snipaste" @click="onImgClicked"> -->
    <img v-if="img_src" ref="imgRef" id="cjimg-snipaste" :class="imgClass" :src="img_src" :style="imgStyle" @load="imgLoad" @error="loadDefaultImg" @click="click"/>
    <img v-else ref="imgRef" id="cjimg-snipaste" :class="imgClass" :src="defaultUrl" :style="imgStyle" @click="click"/>
  <!-- </div> -->
</template>

<script>
import './img.scss'
import './image_dec/dec_img'
// import { observerImage, unobserverImage, isElInViewport } from '../utils/elm_observer'
import { getConfig, isEncodeUrl, downloadEncodeImg } from '@/utils/img'
// import { randomElmId } from '../utils/index'
// import { I_PHOTO, I_PHOTO_FAIL } from '../constants/icon-font'
// import { isNil, get } from '@xy/core'

const STEP = {
  LOADING: 1,
  SHOW: 2,
  ERROR: 3
}

export default {
  /** 组件加载后使用的名字 */
  name: 'cjImg',
  /** 引用组件 */
  components: {},
  props: {
    /** img地址 */
    src: String,
    /** 适配方案 */
    // objectFit: {
    //   type: String,
    //   default: 'cover'
    // },
    imgStyle: {
      type: String,
      default: '',
    },
    imgClass: {
      type: String,
      default: ''
    },
    imgLoad: {
      type: Function,
      default: ()=> {}
    },
    /** 懒加载 */
    lazy: String,
    /** 点击事件 */
    onClick: Function | undefined,
    /** 加载 */
    onLoad: Function | undefined,
    /** 出错 */
    onError: Function | undefined,
    // 是否是启动页
    startPage: {
      type: Boolean,
      default: false
    },
    // 是否需要跨域
    isCrossOrigin: {
      type: Boolean,
      default: false
    },
    defaultImg: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      /** 当前是否在显示区域内 */
      is_appear: undefined,
      /** 图片缓存 */
      cache: {},
      /** 图片缓存key */
      img_md5_key: undefined,
      /** 步骤 */
      step: STEP.LOADING,
      /** svga播放器 */
      svga_player: undefined,
      /** svga解析器 */
      svga_parser: undefined,
      /** 是否是svga */
      is_svga: false,
      /** 是否是字体 */
      is_font: false,
      /** 图片链接 内容 字体名 占位图 */
      img_src: undefined,
      // 图片链接（base64）
      // img_src_base64: undefined,
    }
  },
  computed: {
    /** 图片id */
    // imgId() {
    //   // return this.id || randomElmId('cjimg-', 16)
    // },
    /**img样式 */
    // imgClass: function() {
    //   return 'cjimg-img-fill'
    // },
    /** 当前是否是显示错误状态 */
    isCurError() {
      return this.step === STEP.ERROR
    },
    /** 当前是否是显示正常状态 */
    isShowNormal() {
      return this.step === STEP.SHOW
    },
    /** 当前是否是显示loading状态 */
    isShowLoading() {
      return this.step === STEP.LOADING
    },
    /** 是否显示holder slot */
    // isShowHolderSlot() {
    //   return this.use_holder_slot
    // }
    defaultUrl() {
      let tempDefault = require('../../assets/default_feng_mian.jpg')
      return this.defaultImg || tempDefault
    }
  },
  created() {
    /** img模块是否有懒加载功能 */
    const use_lazy_func = getConfig('use_lazy')
    const _is_appear = !(use_lazy_func && (this.lazy || this.lazy === ''))
    // this.initHolderImg(this.img_holder, '')
    _is_appear && this.refreshImage(this.src, _is_appear)
    this.is_appear = _is_appear
    // Log.log("CJIMG created",this.imgId)
  },
  mounted() {},
  /** 销毁之前调用 */
  beforeDestroy() {
    // this.unobserverImage()
  },
  beforeUpdate() {
    // Log.log("Xxxxx beforeUpdate",this.imgId)
  },
  /** 销毁 */
  destroyed() {
    // this.clearPlaySvga()
  },
  methods: {
    ///////////////////功能函数////////////////////////////
    /** 判断是否js需要解密图片 */
    isNeedDecImg(url) {
      return !this.isLocalRes(url) && getConfig('web_encode_img') && isEncodeUrl(url)
    },
    /** md5 */
    md5(str) {
      return window.CryptoJS ? window.CryptoJS.MD5(str).toString() : str
    },
    /** 判断是否是svg */
    isSvga(url) {
      return typeof url === 'string' && /\.svga$/.test(url)
    },
    /** 判断是否是iconFont */
    isIconFont(url) {
      return this.isLocalRes(url) && !this.isBase64(url) && /^icon_(.)*/.test(url)
    },
    /** 是否是base64图片内容数据 */
    isBase64(url) {
      return this.isLocalRes(url) && /^data:image\//.test(url)
    },
    /** 是否是本地资源 */
    isLocalRes(url) {
      switch (typeof url) {
        case 'string':
          return !/^http/.test(url)
        default:
          return true
      }
    },
    /** 本地资源传入可能是import对象 */
    getSrc(src) {
      switch (typeof src) {
        case 'object':
          return src.default || src
        default:
          return src
      }
    },
    ////////////////////////回调事件处理///////////////////////////////
    /** 点击图片 */
    onImgClicked(e) {
      if (this.step !== STEP.SHOW) return

      if (this.onClick) {
        this.onClick(e)
      } else {
        this.$emit('click', e)
      }
      // this.onClick && this.onClick(e)
      // this.$emit('click', e)
    },
    /** 图片加载完成 */
    onImgLoad(e, is_error_step) {
      !is_error_step && this.onLoad && this.onLoad(e)
    },
    /** 加载图片出错 */
    onImgError(e, is_error_step) {
      /** 如果是加载holder图片出错时显示默认holder图片 */
      // this.initHolderImg(!is_error_step ? this.img_error : undefined, I_PHOTO_FAIL)
      this.img_md5_key = undefined
      this.step = STEP.ERROR
      !is_error_step && this.onError && this.onError(e)
    },
    ///////////////////////////业务流程///////////////////////////
    /** 刷新图片 */
    refreshImage(url, _is_appear) {
      if (!url) {
        this.img_md5_key = undefined
        // this.initHolderImg(this.img_holder, '')
        return
      }
  
      /** 分析是否需要解密 */
      if (this.isNeedDecImg(url)) {
        /** 需要解密url */
        if (_is_appear) {
          /** 当前显示 */
          const img_key = this.md5(url)
          if (!this.img_md5_key || this.img_md5_key != img_key) {
            if (this.cache[img_key]) {
              this.img_md5_key = img_key
              this.calculateSrc({ img_key })
            } else {
              this.requestEncodeImage(url)
            }
          }
        }
      } else {
        /** 使用本地资源或者是无需解密的网络图片 */
        this.img_md5_key = undefined
        this.calculateSrc({ url })
      }
    },

    /** 请求加密数据并解密 */
    requestEncodeImage(url) {
      downloadEncodeImg(url)
        .then((res) => {
          if (res) {
            this.img_md5_key = this.md5(url)
            this.cache[this.img_md5_key] = res
            this.calculateSrc({ img_key: this.img_md5_key })
            this.$forceUpdate()
          } else {
            /** 切换到未加密图片地址 */
            const re_text = new RegExp(getConfig('url_reg'))
            const dev_url = url.replace(re_text, getConfig('url_reg_repalce_dev'))
            this.calculateSrc({ url: dev_url })
          }
        })
        .catch((err) => {
          this.onImgError(err, this.isCurError)
        })
    },
    /** 取消监听 */
    unobserverImage() {
      if (this.is_observer_img) {
        // unobserverImage(this.imgId, this.$vnode.elm)
        this.is_observer_img = undefined
      }
    },
    /** 计算当前显示的src值
     * @params {url,img_key,is_font}
     */
    calculateSrc(params) {
      const { url, img_key, is_font } = params
      this.is_font = url ? this.isIconFont(url) : false
      this.img_src = img_key ? this.cache[img_key] : url
      this.step = STEP.SHOW
    },
    // 缺省影视图片
    loadDefaultImg(e) {
      let img = e.srcElement
      img.src = this.defaultImg || require('../../assets/default_feng_mian.jpg')
    },

    // 点击事件
    click() {
      this.$emit('click')
    }
  },
  watch: {
    src: function(new_val, old_val) {
      // Log.info(`CJImg watch src. new[${new_val}],old[${old_val}]`)
      if (new_val != old_val) {
        // this.icon_err_text="photo"
        this.refreshImage(new_val, this.is_appear, this.startPage, '图片还是icon')
      }
    }
  },
}
</script>
